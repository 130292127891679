import {css} from "@emotion/core";
import * as React from "react";

const NavDrawer = css`
  position: relative;
`
const NavInput = css`
  /* チェックが入ったらもろもろ表示 */
  &:checked ~ #nav-close {
    display: block;/*カバーを表示*/
    opacity: .5;
  }
  
  &:checked ~ #nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);/*中身を表示（右へスライド）*/
    box-shadow: 6px 0 25px rgba(0,0,0,.15);
  }
`
/* チェックボックス等は非表示に */
const NavUnshown = css`
  display: none;
`
/* アイコンのスペース */
const NavOpen = css`
  display: inline-block;
  width: 30px;
  height: 22px;
  vertical-align: middle;
`
/* ハンバーガーアイコンをCSSだけで表現 */
const NavOpenSpanLine = css`
  position: absolute;
  height: 3px;/*線の太さ*/
  width: 25px;/*長さ*/
  border-radius: 3px;
  background: #555;
  display: block;
  content: '';
  cursor: pointer;
`
const NavOpenSpan = css`
  ${NavOpenSpanLine};
  &:before {
    ${NavOpenSpanLine};
    bottom: -8px;
  }
  &:after {
    ${NavOpenSpanLine};
    bottom: -16px;
  }
`
/* 閉じる用の薄黒カバー */
const NavClose = css`
  display: none;/*はじめは隠しておく*/
  position: fixed;
  z-index: 99;
  top: 0;/*全体に広がるように*/
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: .3s ease-in-out;
`
/* 中身 */
const NavContent = css`
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;/*最前面に*/
  width: 60%;/*右側に隙間を作る（閉じるカバーを表示）*/
  max-width: 330px;/*最大幅（調整してください）*/
  height: 100%;
  background: #fff;/*背景色*/
  transition: .3s ease-in-out;/*滑らかに表示*/
  -webkit-transform: translateX(-105%);
  transform: translateX(-105%);/*左に隠しておく*/
`

const NavigationDrawer: React.FC = ({children}) => {
  return (
    <div css={NavDrawer}>
      <input css={css`${NavInput}; ${NavUnshown}`} id={"nav-input"} type="checkbox"/>
      <label css={NavOpen} htmlFor="nav-input"><span css={NavOpenSpan}></span></label>
      <label css={css`${NavUnshown}; ${NavClose}`} id={"nav-close"} htmlFor="nav-input"><span></span></label>
      <div css={NavContent} id={"nav-content"}>
        {children}
      </div>
    </div>
  )
}

export default NavigationDrawer
