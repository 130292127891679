export const isIos = (ua: string) => {
  return /iphone|ipad|ipod/.test(ua)
}
export const isIphone = (ua: string) => {
  return /iphone/.test(ua)
}
export const isAndroid = (ua: string) => {
  return /android/.test(ua)
}
export const isAndroidPhone = (ua: string) => {
  return /android/.test(ua) && /mobile/.test(ua)
}
export const isPC = (ua: string) => {
  return !isIphone(ua) && !isAndroidPhone(ua)
}
