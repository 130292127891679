import * as React from "react"
import {css} from "@emotion/core"

const FooterStyles = css`
`

const Footer: React.FC = () => (
  <footer css={FooterStyles} />
)

export default Footer
