import {Link} from "gatsby"
import * as React from "react"
import styled from "@emotion/styled";
import {css} from "@emotion/core"
import {isPC} from "../util/user-agent";
import NavigationDrawer from "./molecules/NavigationDrawer";
import {useState} from "react";
import {useEffect} from "react";

type Props = {
  to: string
}

const NavLink: React.FC<Props> = ({to, children}) => (
  <StyledLink to={to}>
    {children}
  </StyledLink>
)

const linkStyles = `
  margin-left: 30px;
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  color: #4f4f4f;
  text-decoration: none;
  cursor: pointer;
  transition: .1s ease-out;
  &:hover {
    color: #26a69a;
  }
  @media(max-width: 900px) {
    margin-left: 10px;
    font-size: 22px;
  }
  @media(max-width: 767px) {
    margin-left: 20px;
    font-size: 24px;
    line-height: 48px;
  }
`

const StyledLink = styled(Link)`${linkStyles}`

const AnchorStyles = css(linkStyles)

const Header: React.FC = () => {
  const [ua, setUa] = useState("")
  useEffect(() => {
    setUa(navigator.userAgent.toLowerCase())
  }, [])

  return (
    <>
      {isPC(ua) &&
      <header
        style={{
          height: 100,
          background: `f8f8f8`,
        }}
      >
        <div
          style={{
            textAlign: `right`,
            padding: `36px 30px`,
            maxWidth: 2000,
          }}
        >
          <NavLink to="/">トップ</NavLink>
          <NavLink to="/profile">プロフィール</NavLink>
          <NavLink to="/skill">スキル</NavLink>
          <NavLink to="/portfolio">ポートフォリオ</NavLink>
          <a css={AnchorStyles} href="https://mabui.org/" target="_blank" rel="noopener noreferrer">ブログ</a>
          <NavLink to="/contact">連絡先</NavLink>
        </div>
      </header>
      }
      {!isPC(ua) &&
      <header
        style={{
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 16,
          background: `f8f8f8`,
        }}
      >
        <NavigationDrawer>
          <NavLink to="/">トップ</NavLink>
          <br/>
          <NavLink to="/profile">プロフィール</NavLink>
          <br/>
          <NavLink to="/skill">スキル</NavLink>
          <br/>
          <NavLink to="/portfolio">ポートフォリオ</NavLink>
          <br/>
          <a css={AnchorStyles} href="https://mabui.org/" target="_blank" rel="noopener noreferrer">ブログ</a>
          <br/>
          <NavLink to="/contact">連絡先</NavLink>
        </NavigationDrawer>
      </header>
      }
    </>
  )
}

export default Header
